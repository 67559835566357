var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { id: _vm.id + "-input-group", "label-for": _vm.id } },
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.id + "-collapse",
              expression: "id + '-collapse'"
            }
          ],
          class: { "is-invalid": _vm.v.$error },
          attrs: {
            variant: "primary",
            "aria-describedby": _vm.id + "-live-feedback"
          }
        },
        [_vm._v(_vm._s(_vm.$t(_vm.label)) + " ")]
      ),
      _c(
        "b-collapse",
        { staticClass: "mt-2", attrs: { id: _vm.id + "-collapse" } },
        [
          _c(
            "b-card",
            _vm._l(_vm.options, function(option, key) {
              return _c("b-form-group", {
                key: key,
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var ariaDescribedby = ref.ariaDescribedby
                        return [
                          _c("b-form-checkbox-group", {
                            attrs: {
                              id: "checkbox-group-" + key,
                              name: "checkbox-group-" + key,
                              options: option.periods,
                              "aria-describedby": ariaDescribedby
                            },
                            on: { input: _vm.handleInput },
                            model: {
                              value: _vm.model[key],
                              callback: function($$v) {
                                _vm.$set(_vm.model, key, $$v)
                              },
                              expression: "model[key]"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.model,
                  callback: function($$v) {
                    _vm.model = $$v
                  },
                  expression: "model"
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "b-form-invalid-feedback",
        { attrs: { id: _vm.id + "-live-feedback" } },
        [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }