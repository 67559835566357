<template>
  <b-form-group :id="id + '-input-group'" :label-for="id">
    <b-button
      v-b-toggle="id + '-collapse'"
      variant="primary"
      :class="{ 'is-invalid': v.$error }"
      :aria-describedby="id + '-live-feedback'"
      >{{ $t(label) }}
    </b-button>
    <b-collapse :id="id + '-collapse'" class="mt-2">
      <b-card>
        <b-form-group
          v-for="(option, key) in options"
          v-bind:key="key"
          v-model="model"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            :id="'checkbox-group-' + key"
            :name="'checkbox-group-' + key"
            v-model="model[key]"
            @input="handleInput"
            :options="option.periods"
            :aria-describedby="ariaDescribedby"
          ></b-form-checkbox-group>
        </b-form-group>
      </b-card>
    </b-collapse>
    <b-form-invalid-feedback :id="id + '-live-feedback'">
      {{ $t("validation.required") }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { validationMixin } from "vuelidate";
import i18n from "@/i18n";

export default {
  components: {},
  mixins: [validationMixin],
  name: "WeekAvailabilitySelect",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "availability_placeholder",
    },
    v: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      weekDays: i18n.t("weekDays"),
      dayPeriods: i18n.t("dayPeriods"),
      options: [],
    };
  },
  computed: {
    model: {
      get() {
        return this.value.filter(
          (n) => n.filter((m) => m.length > 0).length > 0
        );
      },
      set(value) {
        this.v.$touch();
        this.$emit("input", value);
      },
    },
  },
  methods: {
    buildOptions() {
      let options = [];
      Object.keys(this.weekDays).forEach((key) => {
        let option = { group: this.weekDays[key] };
        let periods = [];
        Object.keys(this.dayPeriods).forEach((period) => {
          periods.push({
            text: this.weekDays[key] + " " + this.dayPeriods[period],
            value: this.weekDays[key] + " " + this.dayPeriods[period],
          });
        });
        option["periods"] = periods;
        options.push(option);
      });
      return options;
    },
    // eslint-disable-next-line no-unused-vars
    handleInput(e) {
      // Send new value to parent
      this.v.$touch();
      this.$emit("input", this.model);
    },
  },
  mounted() {
    this.options = this.buildOptions();
  },
};
</script>

<style scoped>
::v-deep .custom-control-inline {
  width: 200px;
}
</style>
